import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios"; // Import axios to make API requests
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";

const AddWorshipCenters = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    const formData = new FormData();
    formData.append("church", values.church);
    formData.append("address", values.address);
    formData.append("email", values.email);
    formData.append("tel", values.tel);
    formData.append("noOfUsers", values.noOfUsers);
    formData.append("Date", values.Date);
    formData.append("time", values.time);
    formData.append("longitude", values.longitude);
    formData.append("latitude", values.latitude);
    formData.append("image", values.image); // Append the file image
    formData.append("status", values.status);

    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://jcgnapi.hasthiya.org/api/worshipCenter/createWorshipCenter",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for file upload
          },
        }
      );
      if (response.data.status) {
        setAlertSeverity("success");
        setAlertMessage("Worship Center added successfully");
        setTimeout(() => {
          navigate("/worship-centers"); // Adjust this route as necessary
        }, 2500);
      }
    } catch (error) {
      console.log(error);
      setAlertSeverity("error");
      setAlertMessage(
        `Failed to add Worship Center: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Header
        title="Add Worship Center"
        subtitle="Create a New Worship Center"
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Church Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.church}
                  name="church"
                  error={!!touched.church && !!errors.church}
                  helperText={touched.church && errors.church}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Telephone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.tel}
                  name="tel"
                  error={!!touched.tel && !!errors.tel}
                  helperText={touched.tel && errors.tel}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Number of Users"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.noOfUsers}
                  name="noOfUsers"
                  error={!!touched.noOfUsers && !!errors.noOfUsers}
                  helperText={touched.noOfUsers && errors.noOfUsers}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Date"
                  InputLabelProps={{ shrink: true }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Date}
                  name="Date"
                  error={!!touched.Date && !!errors.Date}
                  helperText={touched.Date && errors.Date}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Latitude"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.latitude}
                  name="latitude"
                  error={!!touched.latitude && !!errors.latitude}
                  helperText={touched.latitude && errors.latitude}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Longitude"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.longitude}
                  name="longitude"
                  error={!!touched.longitude && !!errors.longitude}
                  helperText={touched.longitude && errors.longitude}
                />
              </Box>
              <Box>
                <InputLabel htmlFor="time">Time</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label="Time"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.time}
                  name="time"
                  error={!!touched.time && !!errors.time}
                  helperText={touched.time && errors.time}
                >
                  {/* Populate the options with appropriate times */}
                  {Array.from({ length: 24 }, (_, i) => (
                    <MenuItem key={i} value={`${i}:00`}>
                      {i}:00
                    </MenuItem>
                  ))}
                </Select>
                {touched.time && errors.time && (
                  <FormHelperText
                    style={{ color: "#f44336", paddingLeft: "15px" }}
                  >
                    {errors.time}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label="Status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.status}
                  name="status"
                  error={!!touched.status && !!errors.status}
                  helperText={touched.status && errors.status}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
                {touched.status && errors.status && (
                  <FormHelperText
                    style={{ color: "#f44336", paddingLeft: "15px" }}
                  >
                    {errors.status}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor="image">Upload an Image*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "image",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="image"
                  error={!!touched.image && !!errors.image}
                  helperText={touched.image && errors.image}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  church: yup.string().required("Required"),
  address: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  tel: yup.string().required("Required"),
  noOfUsers: yup.number().required("Required").positive().integer(),
  Date: yup.date().required("Required"),
  time: yup.string().required("Required"),
  longitude: yup.string().required("Required"),
  latitude: yup.string().required("Required"),
  status: yup.string().required("Required"),
  image: yup.mixed().required("File is required"), // Ensure a file is uploaded
});

const initialValues = {
  church: "",
  address: "",
  email: "",
  tel: "",
  noOfUsers: "",
  Date: "",
  time: "",
  longitude: "",
  latitude: "",
  status: "Active",
  image: null,
};

export default AddWorshipCenters;
