import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";

const CreateNewUser = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://jcgnapi.hasthiya.org/api/career/create",
        {
          name: values.employerName,
          jobTitle: values.jobTitle,
          jobDescription: values.jobDescription,
          jobLocation: values.jobLocation,
          jobType: values.jobType,
          duty: values.dutiesResponsibilities,
          keyCompetencies: values.keyCompetencies,
          physicalRequirement: values.physicalRequirements,
          other: values.others,
          duration: values.Duration,
          description: values.employerDescription,
          qualification: values.jobQualifications,
        }
      );

      setAlertSeverity("success");
      setAlertMessage("Career added successfully!");
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/careers");
      }, 2000);
      console.log("Career created successfully:", response.data);
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("Error adding career. Please try again.");
      setOpenSnackbar(true);

      console.error("Error creating career:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box m="20px" height="70vh" overflow="auto" paddingRight="20px">
      <Box width="75%">
        <Header title="Add a New Career" subtitle="" />

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} style={{ height: "41vh" }}>
              {/* Employer Details */}
              <Box>
                {" "}
                <Box>
                  <Box mt={2}>
                    <Typography fontWeight="bold" fontSize="16px">
                      I. Employer Details
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Company/Organization Name*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.employerName}
                      name="employerName"
                      error={!!touched.employerName && !!errors.employerName}
                      helperText={touched.employerName && errors.employerName}
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Company/Organization Description*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.employerDescription}
                      name="employerDescription"
                      multiline
                      rows={4}
                      error={
                        !!touched.employerDescription &&
                        !!errors.employerDescription
                      }
                      helperText={
                        touched.employerDescription &&
                        errors.employerDescription
                      }
                    />
                  </Box>
                </Box>
                {/* Job Details */}
                <Box>
                  <Box mt={4}>
                    <Typography fontWeight="bold" fontSize="16px">
                      II. Job Details
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Job Title*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.jobTitle}
                      name="jobTitle"
                      error={!!touched.jobTitle && !!errors.jobTitle}
                      helperText={touched.jobTitle && errors.jobTitle}
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Job Location*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.jobLocation}
                      name="jobLocation"
                      error={!!touched.jobLocation && !!errors.jobLocation}
                      helperText={touched.jobLocation && errors.jobLocation}
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Job Description*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.jobDescription}
                      name="jobDescription"
                      multiline
                      rows={4}
                      error={
                        !!touched.jobDescription && !!errors.jobDescription
                      }
                      helperText={
                        touched.jobDescription && errors.jobDescription
                      }
                    />
                  </Box>
                </Box>
                {/* Duties and Responsibilities */}
                <Box>
                  <Box mt={4}>
                    <Typography fontWeight="bold" fontSize="16px">
                      III. Duties and Responsibilities
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Duties and Responsibilities*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dutiesResponsibilities}
                      name="dutiesResponsibilities"
                      multiline
                      rows={4}
                      error={
                        !!touched.dutiesResponsibilities &&
                        !!errors.dutiesResponsibilities
                      }
                      helperText={
                        touched.dutiesResponsibilities &&
                        errors.dutiesResponsibilities
                      }
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Job Qualifications*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.jobQualifications}
                      name="jobQualifications"
                      multiline
                      rows={4}
                      error={
                        !!touched.jobQualifications &&
                        !!errors.jobQualifications
                      }
                      helperText={
                        touched.jobQualifications && errors.jobQualifications
                      }
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Key Competencies*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.keyCompetencies}
                      name="keyCompetencies"
                      multiline
                      rows={4}
                      error={
                        !!touched.keyCompetencies && !!errors.keyCompetencies
                      }
                      helperText={
                        touched.keyCompetencies && errors.keyCompetencies
                      }
                    />
                  </Box>
                </Box>
                {/* Final Details */}
                <Box>
                  <Box mt={4}>
                    <Typography fontWeight="bold" fontSize="16px">
                      IV. Final Details
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Physical Requirements*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.physicalRequirements}
                      name="physicalRequirements"
                      multiline
                      rows={4}
                      error={
                        !!touched.physicalRequirements &&
                        !!errors.physicalRequirements
                      }
                      helperText={
                        touched.physicalRequirements &&
                        errors.physicalRequirements
                      }
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Others*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.others}
                      name="others"
                      multiline
                      rows={4}
                      error={!!touched.others && !!errors.others}
                      helperText={touched.others && errors.others}
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Duration of this job to show in Web  (in months)*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Duration}
                      name="Duration"
                      multiline
                      rows={1}
                      error={!!touched.Duration && !!errors.Duration}
                      helperText={touched.Duration && errors.Duration}
                    />
                  </Box>
                </Box>
              </Box>

              <Box display="flex" justifyContent="right" mt={4}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  sx={{
                    backgroundColor: "#6870fa",
                    color: "white",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#3e4396",
                    },
                  }}
                  endIcon={loading && <CircularProgress size={20} />}
                >
                  {loading ? "Adding..." : "Add"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>

        {/* Snackbar for showing alerts */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar(false)}
            severity={alertSeverity}
            elevation={6}
            variant="filled"
            sx={{ color: "#fff" }}
          >
            {alertSeverity === "success" ? "Success" : "Error"}: {alertMessage}
          </MuiAlert>
        </Snackbar>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  employerName: yup.string().required("Company/Organization Name is required"),
  employerDescription: yup
    .string()
    .required("Company/Organization Description is required"),
  jobTitle: yup.string().required("Job Title is required"),
  jobLocation: yup.string().required("Job Location is required"),
  jobDescription: yup.string().required("Job Description is required"),
  dutiesResponsibilities: yup
    .string()
    .required("Duties and Responsibilities are required"),
  jobQualifications: yup.string().required("Job Qualifications are required"),
  keyCompetencies: yup.string().required("Key Competencies are required"),
  physicalRequirements: yup
    .string()
    .required("Physical Requirements are required"),
  others: yup.string().required("Others are required"),
  Duration: yup.string().required("Duration is required"),
});

const initialValues = {
  employerName: "",
  employerDescription: "",
  jobTitle: "",
  jobLocation: "",
  jobDescription: "",
  dutiesResponsibilities: "",
  jobQualifications: "",
  keyCompetencies: "",
  physicalRequirements: "",
  others: "",
  Duration: "",
};

export default CreateNewUser;
